import { lazy } from "react";

const LoginComponent = lazy(() =>
  import("../pages/general").then(({ Login }) => ({ default: Login }))
);

const PrivacyComponent = lazy(() =>
  import("../pages/general").then(({ PrivacyPolicy }) => ({
    default: PrivacyPolicy,
  }))
);

const StructureComponent = lazy(() =>
  import("../pages/authority").then(({ Structure }) => ({ default: Structure }))
);

const UserComponent = lazy(() =>
  import("../pages/authority").then(({ User }) => ({ default: User }))
);

const VersionComponent = lazy(() =>
  import("../pages/authority").then(({ Version }) => ({ default: Version }))
);

/*const UserComponent = lazy(async () => {
  const com = await import("../pages/authority");
  return { default: com.User || com };
});*/

export interface RouteConfigDeclaration {
  /**
   * 当前路由路径
   */
  path: string;
  /**
   * 当前路由名称
   */
  name?: string;
  /**
   * 是否严格匹配路由
   */
  exact?: boolean;
  /**
   * 是否需要路由鉴权
   */
  auth?: boolean;
  /**
   * 是否需要路由重定向
   */
  isRedirect?: boolean;
  /**
   * 是否需要动态加载路由
   */
  isDynamic?: boolean;
  /**
   * 动态加载路由时的提示文案
   */
  loadingFallback?: string;
  /**
   * 路由组件
   */
  element: any;
  /**
   * 子路由
   */
  children?: RouteConfigDeclaration[];
}
const routes: RouteConfigDeclaration[] = [
  {
    path: "/login",
    element: LoginComponent,
    exact: true,
    auth: false,
  },
  {
    path: "/privacy",
    element: PrivacyComponent,
    exact: true,
    auth: false,
  },
  {
    path: "/*",
    element: StructureComponent,
    exact: false,
    auth: false,
    children: [
      {
        path: "/account",
        element: UserComponent,
        exact: false,
      },
      {
        path: "/version",
        element: VersionComponent,
        exact: false,
      },
    ],
  },
];
export default routes;
