import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login: false,
  logout: 0,
  level: 1,
  currentChangingEmail: "",
  currentChangingCode: "",
  userListState: {} as any,
  petsListState: {} as any,
  email: "",
  code: "",
};

const authSlice = createSlice({
  name: "authority",
  initialState,
  reducers: {
    validateToken(state, action) {
      if (action.payload.result === "success") {
        state.login = true;
      } else {
        state.login = false;
        if (action.payload.result === "fail") state.logout = 1;
      }
    },
    changeCurrent(state, action) {
      if (action.payload.result === "store") {
        state.currentChangingEmail = action.payload.email;
        state.currentChangingCode = action.payload.code;
      } else {
        state.currentChangingEmail = "";
        state.currentChangingCode = "";
      }
    },
    setUserListState(state, action) {
      if (action.payload.behaviour === "fill") {
        state.userListState = action.payload.state;
      } else {
        state.userListState = {};
      }
    },
    setPetsListState(state, action) {
      if (action.payload.behaviour === "fill") {
        state.petsListState = action.payload.state;
      } else {
        state.petsListState = {};
      }
    },
    setEmail(state, action) {
      if (action.payload.behaviour === "fill") {
        state.email = action.payload.email;
      } else {
        state.email = "";
      }
    },
    setCode(state, action) {
      if (action.payload.behaviour === "fill") {
        state.code = action.payload.code;
      } else {
        state.code = "";
      }
    },
  },
});

export const {
  validateToken,
  changeCurrent,
  setUserListState,
  setPetsListState,
  setEmail,
  setCode,
} = authSlice.actions;

export default authSlice.reducer;
