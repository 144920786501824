import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { validateToken } from "../app/authority";

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL,
});
instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("surgical-admin-satoken");
    if (token) {
      (config.headers as AxiosRequestHeaders)["surgical-admin-satoken"] = token;
    }
    (config.headers as AxiosRequestHeaders)["Accept-Language"] = "en-US";
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

const AxiosInterceptor = ({ children }: any) => {
  const stateRef = useRef() as any;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  stateRef.current = error;

  const status = useSelector((state: any) => state.auth);

  const resInterceptor = (response: any) => {
    let code = JSON.stringify(response.data.code);
    let fristChar = code.slice(0, 1);
    let location = window.location.href;

    if (fristChar === "5") {
      if (
        location.indexOf("/login") !== -1 ||
        location.indexOf("/reset") !== -1
      ) {
        return response;
      } else {
        sessionStorage.clear();
        localStorage.clear();
        dispatch(
          validateToken({
            result: "fail",
          })
        );
        return;
      }
    } else if (code === "666") {
      // 在这里dispatch一个action
      return;
    } else {
      return response;
    }
  };

  const errInterceptor = (error: any) => {
    return Promise.reject(error);
  };

  const interceptor = instance.interceptors.response.use(
    resInterceptor,
    errInterceptor
  );

  useEffect(() => {
    return () => instance.interceptors.response.eject(interceptor);
  }, [navigate]);

  useEffect(() => {
    let location = window.location.href;
    if (
      location.indexOf("/login") === -1 &&
      location.indexOf("/reset") === -1 &&
      status.logout === 1
    ) {
      navigate("/login");
    }
  }, [status.logout]);

  return children;
};

export default instance;
export { AxiosInterceptor };
