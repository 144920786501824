import { Suspense } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { AxiosInterceptor } from "./request/index";
import routes from "./router";

const App = () => {
  return (
    <HashRouter>
      <Suspense>
        <AxiosInterceptor>
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  key={index}
                  element={
                    <route.element
                      routes={route.children ? route.children : []}
                    />
                  }
                ></Route>
              );
            })}
          </Routes>
        </AxiosInterceptor>
      </Suspense>
    </HashRouter>
  );
};

export default App;
